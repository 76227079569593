<template>
  <div class="header-wrapper">
    <header class="header">
      <div class="container">
        <div class="header-top">
          <div class="header-top__left">
            <!-- <button class="header-top__btn burger" @click="openMenu"></button> -->
            <button
              class="burger"
              @click="openMenu"
              :class="{ 'is-active': isOpenMenu }"
            >
              <span class="burger-line burger-top"></span>
              <span class="burger-line burger-middle"></span>
              <span class="burger-line burger-bottom"></span>
            </button>
            <locale-router-link
              v-if="isShowHeader"
              :to="`/`"
              class="header-top__logo"
              ><img
                v-if="isCorp"
                class="logo-img"
                src="@/assets/img/icons/magnum-corp.svg"
                alt="Логотип"
                width="221"
                height="40"
              />
              <img
                v-else
                class="logo-img"
                src="@/assets/img/icons/logo.svg"
                alt="Логотип"
                width="221"
                height="40"
              />
              <img
                class="logo-img-mobile"
                src="@/assets/img/icons/logo-mobile.svg"
                alt="Логотип"
                width="43"
                height="25"
              />
            </locale-router-link>
            <!-- <locale-router-link
              v-if="isCorp"
              :to="`/`"
              class="header-top__magnum"
              >Magnum</locale-router-link
            > -->
            <!-- <locale-router-link v-else to="/corp" class="header-top__magnum"
              >Magnum Corp.</locale-router-link
            > -->
            <a href="https://corp.magnum.kz/ru" class="header-top__magnum"
              >Magnum Corp.</a
            >
          </div>
          <div v-if="isShowHeader" class="header-top__search">
            <form @submit.prevent.enter="searchStart">
              <input
                v-model="termSearch"
                type="text"
                :placeholder="$t('findAll')"
              />
              <div class="header-top__search-btn search-link-btn">
                <a
                  class="search-link-selected search-link-toggle"
                  @click.prevent="selectedSearch()"
                >
                  {{ $t(searchTitle) }}

                  <img
                    class="header-top__selected-image"
                    src="@/assets/img/icons/arrow-gray.svg"
                  />
                </a>
                <div
                  class="header-top__search-list header-menu-select"
                  :class="{ 'is-active': selected }"
                >
                  <a
                    v-for="(item, index) in search"
                    :key="index"
                    class="search-link search-link--item"
                    @click="replacement(item)"
                    >{{ $t(item.label) }}</a
                  >
                </div>
              </div>
            </form>
          </div>
          <h3 v-if="isShowSearchTitle" class="header-search-title">Поиск</h3>
          <div class="header-top__settings">
            <div
              v-if="selectedCity"
              class="header-top__address"
              @click="openSelectCity"
            >
              {{ selectedCity.attributes.name.substring(0, 3) }}
            </div>
            <lang-change />
          </div>
        </div>
      </div>
    </header>

    <nav class="header-nav" refs="headerNav">
      <div class="container">
        <ul class="header-nav__list">
          <li class="header-nav__item">
            <locale-router-link
              to="catalog"
              class="header-nav__stock color-purple catalog-stocks-link"
            >
              {{ $t("discountCatalog") }}
            </locale-router-link>
          </li>
          <li class="header-nav__item stocks-link-item">
            <locale-router-link
              to="stocks"
              class="header-nav__stock color-yellow stocks-link router-link-active"
              >{{ $t("stock") }}</locale-router-link
            >
          </li>
          <li class="header-nav__item">
            <locale-router-link to="magnumclub" class="header-nav__link">
              Magnum Club
            </locale-router-link>
          </li>
          <li class="header-nav__item">
            <locale-router-link to="magnumgo" class="header-nav__link">
              Magnum Go
            </locale-router-link>
          </li>
          <li class="header-nav__item">
            <locale-router-link to="private-label" class="header-nav__link">
              {{ $t("ourBrands") }}
            </locale-router-link>
          </li>
          <li class="header-nav__item">
            <locale-router-link to="cooking" class="header-nav__link">
              {{ $t("cookingWithMagnum") }}
            </locale-router-link>
          </li>
          <li class="header-nav__item">
            <locale-router-link to="shops" class="header-nav__link">
              {{ $t("shops") }}
            </locale-router-link>
          </li>
          <li class="header-nav__item">
            <locale-router-link to="contacts" class="header-nav__link">
              {{ $t("contacts") }}
            </locale-router-link>
          </li>
          <li class="header-nav__item about-company-link">
            <p class="header-nav__link">
              {{ $t("aboutCompany") }}
            </p>
            <div class="header-nav__about">
              <locale-router-link to="about">{{
                $t("magnumCompany")
              }}</locale-router-link>
              <locale-router-link to="news">{{
                $t("companyNews")
              }}</locale-router-link>
              <locale-router-link to="career">{{
                $t("сareerMagnum")
              }}</locale-router-link>
              <!-- <<locale-router-link to="">Мы в СМИ</locale-router-link> -->
            </div>
          </li>
        </ul>
      </div>
    </nav>

    <div class="underheader"></div>
    <button v-if="isShowScrollBtn" class="scroll__top" @click="scrollTop">
      <img src="@/assets/img/icons/scroll-top-arrow.svg" alt="" />
    </button>

    <app-modal-city v-if="isShowModalCity" @close="isShowModalCity = false" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store";
import { actionTypes as actionTypesSearch } from "@/store/modules/search";
import AOS from "aos";

export default {
  name: "AppHeader",
  components: {
    LangChange: () => import("@/components/language/LangChange.vue"),
    AppModalCity: () => import("@/components/modal/AppModalCity.vue"),
  },
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: false,
      searchTitle: "",
      termSearch: "",
      searchEntity: null,
      headerSearch: false,
      isShowScrollBtn: true,
      isCorp: false,
      isShowModalCity: false,

      search: [
        {
          label: "byGoods",
          entity: "products",
        },
        {
          label: "byShares",
          entity: "promos",
        },
        {
          label: "onTheNews",
          entity: "news",
        },
        {
          label: "prescription",
          entity: "recipes",
        },
      ],
    };
  },

  computed: {
    ...mapState(["isOpenMenu"]),
    ...mapState("cityModule", ["selectedCity"]),
    isShowHeader() {
      if (
        this.$router.app._route.fullPath === `/${this.$i18n.locale}/search` &&
        this.$isMobile()
      ) {
        return false;
      }
      return true;
    },
    isShowSearchTitle() {
      if (this.$router.app._route.fullPath === `/${this.$i18n.locale}/search`) {
        return true && this.$isMobile();
      }
      return false && this.$isMobile();
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        if (this.$route.path === `/${this.$i18n.locale}/corp`) {
          this.isCorp = true;
        } else {
          this.isCorp = false;
        }
        if (this.$route.path === `/${this.$i18n.locale}/contacts`) {
          this.isShowScrollBtn === false;
        } else {
          this.isShowScrollBtn === true;
        }
        this.showScrollBtn();
      },
    },
    isOpenMenu: {
      handler(newVal) {
        if (newVal) {
          document.querySelector("html").style.overflow = "hidden";
        } else {
          document.querySelector("html").style.overflow = "initial";
        }
      },
    },
  },
  mounted() {
    if (this.$router === `/${this.$i18n.locale}/search`) {
      this.headerSearch = true;
    }

    if (this.$route.path === `/${this.$i18n.locale}/corp`) {
      this.isCorp = true;
    }

    this.showScrollBtn();

    this.searchTitle = this.search[0].label;
    this.searchEntity = this.search[0].entity;
    this.addClassScrollBtn();

    if (!localStorage.getItem("selectedCity")) {
      this.isShowModalCity = true;
    }
  },
  methods: {
    ...mapActions({
      openMenu: actionTypes.changeIsOpenMenu,
      openSelectCity: actionTypes.changeIsOpenSelectCity,
    }),
    ...mapActions("searchModule", {
      searchTerm: actionTypesSearch.getSearchTerm,
      changeIsSearchStart: actionTypesSearch.getIsSearchStart,
      entitySearch: actionTypesSearch.getSearchEntity,
      labelSearch: actionTypesSearch.getSearchLabel,
    }),
    selectedSearch() {
      this.selected = !this.selected;
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },

    searchStart() {
      if (this.$route.path === `/${this.$i18n.locale}/search`) {
        this.searchTerm(this.termSearch);
        this.entitySearch(this.searchEntity);
        this.labelSearch(this.$t(this.searchTitle));
        this.changeIsSearchStart(true);
      } else {
        this.$router.push(`/${this.$i18n.locale}/search`);
        this.searchTerm(this.termSearch);
        this.entitySearch(this.searchEntity);
        this.labelSearch(this.$t(this.searchTitle));
        this.changeIsSearchStart(true);
      }
    },

    replacement(item) {
      this.searchTitle = item.label;
      this.selected = false;
      this.searchEntity = item.entity;
    },
    showScrollBtn() {
      if (this.$isMobile()) {
        switch (this.$router.app._route.fullPath) {
          case `/${this.$i18n.locale}/`:
            this.isShowScrollBtn = true;
            break;
          case `/${this.$i18n.locale}/catalog`:
            this.isShowScrollBtn = true;
            break;
          default:
            this.isShowScrollBtn = false;
            break;
        }
      }
    },
    addClassScrollBtn() {
      const header = document.querySelector(".header-wrapper");
      const body = document.querySelector("body");
      const onscroll = (e) => {
        e.stopPropagation();
        AOS.refresh();
        let scroll = window.pageYOffset || document.documentElement.scrollTop;
        if (scroll >= 150) {
          header?.classList?.add("is-fixed");
          body?.classList?.add("active");
        } else {
          header?.classList?.remove("is-fixed");
          body?.classList?.remove("active");
        }
        if (scroll >= 400) {
          document.querySelector(".scroll__top")?.classList.add("is-show");
        } else {
          document.querySelector(".scroll__top")?.classList.remove("is-show");
        }
      };
      document.addEventListener("scroll", onscroll);
    },
  },
};
</script>

<style lang="scss" scoped>
.is-fixed {
  width: 100%;
  background: #fff;
  transition: 0.3s;
}

.header .container {
  position: relative;
}
</style>
